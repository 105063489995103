import { Form, Formik } from "formik"
import AssessmentFormMatrix from "./AssessmentFormMatrix"
import AssessmentFormGroup from "./AssessmentFormGroup";
import AssessmentFormQuestion from "./AssessmentFormQuestion";
import * as Yup from "yup"
import _ from "lodash"
import {
    Spinner,
} from 'react-bootstrap';
import { Button } from "primereact/button"
import { useToasts } from 'react-toast-notifications';
import { validateMultiValueType, validateSingeValueType } from "./helpers/conditionalQuestionValidator";
import "./styles/form-styles.css"

const AssessmentFormQuestionContainer = ({ questions, onSubmitValues, answers, onCancel, disabled }) => {

    const { addToast } = useToasts();

    const RenderQuestions = ({ questions, values, onSetFieldValue }) => {
        return (
            <>
                {questions.map((question) => {
                    if (question.matrix) {
                        return (
                            <AssessmentFormMatrix
                                matrix={question.matrix}
                                answers={values.answers}
                                onSetFieldValue={onSetFieldValue}
                                key={question.id}
                                readOnly={false}
                                displayAnswers={null}
                                displayAnswerValues={null}
                                displayScore={false}
                                disabled={disabled}
                            />
                        )
                    } else if (question.questionGroup) {
                        return (
                            <AssessmentFormGroup
                                group={question.questionGroup}
                                answers={values.answers}
                                onSetFieldValue={onSetFieldValue}
                                key={question.id}
                                readOnly={false}
                                displayAnswers={null}
                                displayAnswerValues={null}
                                displayScore={false}
                                disabled={disabled}
                            />
                        )
                    } else if (question.question) {
                        return (
                            <AssessmentFormQuestion
                                question={question.question}
                                answers={values.answers}
                                onSetFieldValue={onSetFieldValue}
                                key={question.id}
                                readOnly={false}
                                displayAnswers={true}
                                displayAnswerValues={null}
                                displayScore={false}
                                disabled={disabled}
                            />
                        )
                    } else {
                        return <></>
                    }
                })}
            </>
        )

    }
    Yup.addMethod(Yup.array, "validateAssessment", function () {
        return this.test("validateAssessmentAnswers", "", function (answerArray) {
            const errors = []

            answerArray.forEach((answer, index) => {
                //check if the answer is required and has an answer
                let isRequired = answer.required

                if (answer.conditionalSource) {
                    isRequired = getConditionalRequired(answer, answerArray)
                }

                if (isRequired) {
                    if (answer.type === "checkbox") {
                        if (
                            answer.multiValues.filter(
                                (value) => value.value && value.value !== "false"
                            ).length === 0
                        ) {
                            answer.multiValues.forEach((multiValue, secondIndex) => {
                                errors.push(
                                    this.createError({
                                        path: `${this.path}[${index}].multiValues[${secondIndex}].value`,
                                        message: "",
                                    })
                                )
                            })
                            errors.push(
                                this.createError({
                                    path: `${this.path}[${index}].value`,
                                    message:
                                        "Please specify an answer for this required question",
                                })
                            )
                        }
                    } else if (!answer.value) {
                        errors.push(
                            this.createError({
                                path: `${this.path}[${index}].value`,
                                message: "Please specify an answer for this required question",
                            })
                        )
                    }
                }
            })

            if (!_.isEmpty(errors)) {
                throw new Yup.ValidationError(errors)
            }
            return true
        })
    })
    const validationSchema = Yup.object().shape({
        answers: Yup.array()
            .of(
                Yup.object().shape({
                    questionId: Yup.string(),
                    conditionalDisplay: Yup.string().nullable(),
                    conditionalRequired: Yup.string().nullable(),
                    conditionalSource: Yup.string().nullable(),
                    conditionalType: Yup.string().nullable(),
                    conditionalValue: Yup.string().nullable(),
                    value: Yup.string().nullable(),
                    multiValues: Yup.array().of(
                        Yup.object().shape({
                            value: Yup.string().nullable(),
                        })
                    ),
                })
            )
            .validateAssessment(),
    })
    const getConditionalRequired = (conditionalAnswer, answerList) => {
        let sourceAnswer = answerList.find(
            (source) => source.questionId === conditionalAnswer.conditionalSource
        )

        let conditionResult = undefined

        if (sourceAnswer.type === "radio") {
            conditionResult = validateMultiValueType(
                conditionalAnswer,
                sourceAnswer.multiValues
            )
        } else {
            conditionResult = validateSingeValueType(
                conditionalAnswer,
                sourceAnswer.value
            )
        }

        if (!sourceAnswer) {
            addToast(
                "Missing source question detected, defaulting field to required",
                {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 10000,
                },
            );
        }
        return conditionResult ? conditionResult.required : true
    }
    return (
        <Formik
            initialValues={answers}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
                onSubmitValues(values)
            }}

        >
            {({ isSubmitting, dirty, values, setFieldValue, errors }) => (
                <Form autoComplete="off" className="form">
                    <div className="formLayout">
                        <div className="formLayoutBody">
                            {!questions ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: 10, height: 22, width: 22 }}
                                />
                            ) : (
                                <RenderQuestions
                                    questions={questions}
                                    values={values}
                                    onSetFieldValue={setFieldValue}
                                />
                            )}
                        </div>
                        <div className="formLayoutFooter">
                            <div className="buttonContainer">
                                <Button
                                    label="Save"
                                    className="assessmentButton assessmentSaveButton"
                                    type="submit"
                                    disabled={(!dirty || disabled)}
                                    icon={disabled && "pi pi-spin pi-spinner"}
                                />
                                <Button
                                    label="Cancel"
                                    className="assessmentButton assessmentCancelButton"
                                    type="button"
                                    onClick={() => { onCancel() }}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
export default AssessmentFormQuestionContainer