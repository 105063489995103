import Accordion from "react-bootstrap/Accordion"
import AssessmentFormQuestion from "../AdditionalAssesments/AssessmentFormQuestion"

import "./styles/form-styles.css"


export default function AssessmentFormGroup({
    group,
    answers,
    readOnly,
    onSetFieldValue,
    displayAnswers,
    displayAnswerValues,
    displayScore,
    disabled
}) {
    return (
        <Accordion
            defaultActiveKey="0"
            className={`${"assessmentGroupContainer"}`}
        >
            <Accordion.Item eventKey="0">
                <Accordion.Header>{group.name}</Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            group.alignment === "horizontal"
                                ? "assessmentGroupContainerHorizontal"
                                : ""
                        }
                    >
                        {group.questions.map((question) => {
                            return (
                                <AssessmentFormQuestion
                                    question={question}
                                    answers={answers}
                                    onSetFieldValue={onSetFieldValue}
                                    key={question.id}
                                    readOnly={readOnly}
                                    displayAnswers={displayAnswers}
                                    displayAnswerValues={displayAnswerValues}
                                    displayScore={displayScore}
                                    disabled={disabled}
                                />
                            )
                        })}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}