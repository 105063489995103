import { createSlice } from "@reduxjs/toolkit";

export const additionalAssessmentsSlice = createSlice({
  name: "additionalAssessments",
  initialState: {
    id: null,
    origin: "",
    forms: [
      {
        key: "recovery-capital-assessment",
        value: "Recovery Capital Assessment",
      },
    ],
  },
  reducers: {
    setDrupalResponse: (state, action) => {
      state.id = action.payload;
    },
    setOrigin: (state, action) => {
      state.origin = action.payload;
    },
    setAdditionalData:(state,action)=>{
      state.additionalData = action.payload
    }
  },
});

export const { setDrupalResponse, setOrigin, setAdditionalData } =
  additionalAssessmentsSlice.actions;

export default additionalAssessmentsSlice.reducer;
