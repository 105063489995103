import { validateMultiValueType, validateSingeValueType } from "./helpers/conditionalQuestionValidator"
import { useToasts } from 'react-toast-notifications';
import AssessmentFormQuestionInput from "../AdditionalAssesments/AssessmentFormQuestionInput"

import "./styles/form-styles.css"


export default function AssessmentFormQuestion({
    question,
    answers,
    readOnly,
    displayAnswers,
    displayAnswerValues,
    displayScore,
    disabled
}) {
    const { addToast } = useToasts();

    const values = answers?.filter((answer) => answer.questionId === question.id)
    const answerIndex = values.length > 0 ? answers.indexOf(values[0]) : -1

    let displaySetting = true
    let requiredSetting = question.required

    if (question.conditionalSource) {
        let sourceAnswer = answers.find(
            (answer) => answer.questionId === question.conditionalSource
        )

        if (sourceAnswer) {
            let conditionResult = undefined

            if (sourceAnswer.type === "radio") {
                conditionResult = validateMultiValueType(
                    question,
                    sourceAnswer.multiValues
                )
            } else {
                conditionResult = validateSingeValueType(question, sourceAnswer.value)
            }

            if (!conditionResult) {
                addToast(
                    "Unable to validate conditional setup, ignoring conditional setup",
                    {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 10000,
                    },
                );
                requiredSetting = true
            } else {
                requiredSetting = conditionResult.required
                displaySetting = conditionResult.display
            }
        } else {
            addToast(
                "Missing source question detected, ignoring conditional setup",
                {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 10000,
                },
            );
            requiredSetting = true
        }
    }

    const calculateScore = () => {
        let score = 0
        let showScore = false
        const myAnswers = answers.filter(
            (answer) => answer.questionId === question.id
        )

        myAnswers.forEach((answer) => {
            if (!["textinput", "date"].includes(answer.type)) {
                if (["dropdown", "radio"].includes(answer.type)) {
                    let answerOption = answer.multiValues.filter(
                        (option) => option.optionId === answer.value
                    )
                    if (answerOption.length > 0) {
                        score = score + (answerOption[0].scoreValue || 0)
                    }
                } else if (answer.type === "number" && answer.scoreFromAnswer) {
                    score = score + (+answer.value || 0)
                } else if (answer.type === "checkbox") {
                    let answerOption = answer.multiValues.filter((option) => option.value)
                    if (answerOption.length > 0) {
                        score = score + (answerOption[0].scoreValue || 0)
                    }
                }
                showScore = true
            }
        })

        return showScore ? (
            <>
                <span className={"assessStyles.scoreLabel"}>Score:</span>{" "}
                <span className={"assessStyles.scoreValue"}>{score}</span>
            </>
        ) : null
    }

    if (!displaySetting) {
        return null
    }

    return (
        <div
            className={
                displayScore
                    ? "questionLayoutWithScore"
                    : "questionLayout"
            }
        >
            <div className="questionLayoutName">
                {question.text}
                {requiredSetting ? (
                    <span className="assessmentRequired">{" *"}</span>
                ) : null}
            </div>
            {displayScore ? (
                <div className="questionLayoutScore">
                    {calculateScore()}
                </div>
            ) : null}
            {displayAnswers !== false ? (
                <div
                    className={`${"questionLayoutBody"}${question.optionAlignment === "horizontal"
                        ? "horizontalQuestion"
                        : ""
                        }`}
                >
                    <AssessmentFormQuestionInput
                        answerIndex={answerIndex}
                        answers={answers}
                        question={question}
                        readOnly={readOnly}
                        displayAnswerValues={displayAnswerValues}
                        disabled={disabled}
                    />
                </div>
            ) : null}
        </div>
    )
}