import Accordion from "react-bootstrap/Accordion"
import AssessmentFormGroup from "../AdditionalAssesments/AssessmentFormGroup"

export default function AssessmentFormMatrix({
    matrix,
    answers,
    readOnly,
    onSetFieldValue,
    displayAnswers,
    displayAnswerValues,
    displayScore,
    disabled
}) {
    return (
        <Accordion
            defaultActiveKey="0"
            className={"assessmentMatrixContainer"}
        >
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    {matrix.name}
                </Accordion.Header>
                <Accordion.Body>
                    {matrix.groups.map((group) => {
                        return (
                            <AssessmentFormGroup
                                group={group}
                                answers={answers}
                                onSetFieldValue={onSetFieldValue}
                                key={group.id}
                                readOnly={readOnly}
                                displayAnswers={displayAnswers}
                                displayAnswerValues={displayAnswerValues}
                                displayScore={displayScore}
                                disabled={disabled}
                            />
                        )
                    })}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}