import { InputNumber } from "primereact/inputnumber"
import { useField } from "formik"

import { Skeleton } from "primereact/skeleton"

//Style
import "./formikInputStyles.css"
export default function FormikNumberInput({
  name,
  label,
  options,
  helpText,
  placeHolderValue,
  disabled,
  required,
  isInitialLoad,
  minFractionDigits,
  minValue,
  maxValue,
  currencyCode,
}) {
  const [field, meta, helpers] = useField(name)

  const getInput = () => {
    if (isInitialLoad) {
      return (
        <Skeleton
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          height="42px"
        />
      )
    } else {
      return (
        <>
          <InputNumber
            value={field.value}
            name={name}
            disabled={disabled}
            placeholder={placeHolderValue}
            onChange={(e) => {
              helpers.setValue(e.value)
            }}
            className={meta.touched && meta.error ? "p-invalid" : undefined}
            style={{ width: "100%" }}
            minFractionDigits={
              minFractionDigits !== undefined ? minFractionDigits : 2
            }
            min={minValue}
            max={maxValue}
            mode={!!currencyCode ? "currency" : "decimal"}
            currency={currencyCode || undefined}
          />
          {meta.touched && meta.error ? (
            <label id={`${name}-error`} className={"errFieldMsg"}>
              {meta.error}
            </label>
          ) : null}
        </>
      )
    }
  }

  const labelField = () => {
    if (isInitialLoad) {
      return <Skeleton width="10rem" height="1rem" />
    } else {
      return (
        <label>
          {label} {required ? <span style={{ color: "red" }}> * </span> : null}
        </label>
      )
    }
  }

  return (
    <div className={"field"}>
      <label>{labelField()}</label>
      <div>
        {helpText ? (
          <div style={{ marginBottom: "10px" }}>
            <small style={{ color: "gray" }}>{helpText}</small>
          </div>
        ) : null}
        {getInput()}
      </div>
    </div>
  )
}
