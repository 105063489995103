//Formik
import FormikNumberInput from "../../helpers/formikInputs/FormikNumberInput"
import FormikCheckboxInput from "../../helpers/formikInputs/FormikCheckboxInput"
import FormikDropDown from "../../helpers/formikInputs/FormikDropDown"
import FormikTextInput from "../../helpers/formikInputs/FormikTextinput"
import FormikCalendar from "../../helpers/formikInputs/FormikCalendar"
import FormikRadioButtonInput from "../../helpers/formikInputs/FormikRadioButtonInput"
import { useField } from "formik"

//Styles
import "./styles/form-styles.css"

export default function AssessmentFormQuestionInput({
    question,
    answers,
    answerIndex,
    readOnly,
    displayAnswerValues,
    disabled
}) {
    let valueFieldName = displayAnswerValues === false ? "NoValue" : "value"
    const singleValueName = `answers[${answerIndex}].${valueFieldName}`

    const [, meta] = useField(singleValueName)

    if (answerIndex >= 0) {
        switch (question.type) {
            case "textinput":
                return <FormikTextInput name={singleValueName} disabled={readOnly} />
            case "number":
                return (
                    <FormikNumberInput
                        name={singleValueName}
                        minFractionDigits={0}
                        disabled={readOnly || disabled}
                    />
                )
            case "date":
                return <FormikCalendar name={singleValueName} disabled={readOnly || disabled} />
            case "checkbox":
                return (
                    <>
                        {answers[answerIndex].multiValues.map((value, index) => {
                            return (
                                <FormikCheckboxInput
                                    checkboxLabel={value.text}
                                    name={`answers[${answerIndex}].multiValues[${index}].${valueFieldName}`}
                                    disabled={readOnly || disabled}
                                />
                            )
                        })}
                        {meta.touched && meta.error ? (
                            <div className="errorLabel">
                                <label
                                    id={`${singleValueName}-error`}
                                    className="errFieldMsg"
                                >
                                    {meta.error}
                                </label>
                            </div>
                        ) : null}
                    </>
                )
            case "DropDown":
                return (
                    <FormikDropDown
                        name={singleValueName}
                        options={answers[answerIndex].multiValues}
                        optionLabel={"text"}
                        optionValue={"optionId"}
                        disabled={readOnly || disabled}
                    />
                )
            case "dropdown":
                return (
                    <FormikDropDown
                        name={singleValueName}
                        options={answers[answerIndex].multiValues}
                        optionLabel={"text"}
                        optionValue={"optionId"}
                        disabled={readOnly || disabled}
                    />
                )
            case "radio":
                return (
                    <FormikRadioButtonInput
                        name={singleValueName}
                        options={answers[answerIndex].multiValues}
                        optionLabel={"text"}
                        optionValue={"optionId"}
                        disabled={readOnly || disabled}
                    />
                )
            default:
                return <>Unknown question input type</>
        }
    } else {
        return <>Answer not found</>
    }
}
