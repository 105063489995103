import { useField } from "formik"

import { Dropdown } from "primereact/dropdown"
import { Skeleton } from "primereact/skeleton"

//Style
import "./formikInputStyles.css"

export default function FormikDropDown({
  name,
  label,
  options,
  optionLabel,
  optionValue,
  helpText,
  placeHolderValue,
  disabled,
  showClear,
  required,
  isInitialLoad,
  onChangeCallback,
}) {
  const [field, meta, helpers] = useField(name)

  const getDropDown = () => {
    if (isInitialLoad) {
      return (
        <Skeleton
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          height="42px"
        />
      )
    } else {
      return (
        <>
          <Dropdown
            value={field.value}
            name={name}
            options={options}
            optionLabel={optionLabel}
            optionValue={optionValue}
            disabled={disabled}
            showClear={showClear}
            placeholder={
              !placeHolderValue ? `Please select an entry` : placeHolderValue
            }
            onChange={(e) => {
              helpers.setValue(e.value)

              if (!!onChangeCallback) {
                onChangeCallback(e.value)
              }
            }}
            className={meta.touched && meta.error ? "p-invalid" : undefined}
            style={{ width: "100%" }}
          />
          {meta.touched && meta.error ? (
            <label id={`${name}-error`} className={"errFieldMsg"}>
              {meta.error}
            </label>
          ) : null}
        </>
      )
    }
  }

  const labelField = () => {
    if (isInitialLoad) {
      return <Skeleton width="10rem" height="1rem" />
    } else {
      return (
        <label>
          {label} {required ? <span style={{ color: "red" }}> * </span> : null}
        </label>
      )
    }
  }

  return (
    <div className={"field"}>
      {label && <label>{labelField()}</label>}
      <>
        {helpText ? (
          <div style={{ marginBottom: "10px" }}>
            <small style={{ color: "gray" }}>{helpText}</small>
          </div>
        ) : null}
        {getDropDown()}
      </>
    </div>
  )
}
