import { useField } from "formik"
import React from "react"
import { Checkbox } from "primereact/checkbox"
import { Skeleton } from "primereact/skeleton"
import { InputSwitch } from "primereact/inputswitch"

//Style
import "./formikInputStyles.css"

export default function FormikCheckBox(props) {
  const [field, meta] = useField(props.name)

  const getCheckbox = () => {
    if (props.isInitialLoad) {
      return <Skeleton height="42px" width="42px" />
    } else {
      return (
        <>
          <div className="control">
            {props.useSwitch ? (
              <InputSwitch
                {...field}
                {...props}
                checked={field.value}
                className={`${"assessmentCheckBox"} ${
                  meta.touched && meta.error ? "p-invalid" : ""
                }`}
                disabled={props.disabled}
              />
            ) : (
              <Checkbox
                {...field}
                {...props}
                checked={field.value}
                className={`${"assessmentCheckBox"} ${
                  meta.touched && meta.error !== undefined ? "p-invalid" : ""
                }`}
                disabled={props.disabled}
              />
            )}
            {!!props.checkboxLabel ? (
              <label style={{ marginLeft: "10px" }}>
                {props.checkboxLabel}
              </label>
            ) : null}
          </div>
        </>
      )
    }
  }

  const getLabel = () => {
    if (props.isInitialLoad) {
      return <Skeleton width="10rem" height="1rem" />
    } else {
      return <label>{props.label}</label>
    }
  }

  return (
    <div className={`${"field"} ${props.className || ""}`}>
      <div className="label">{getLabel()}</div>

      {props.helpText ? (
        <div style={{ marginBottom: "10px" }}>
          <small style={{ color: "gray" }}>{props.helpText}</small>
        </div>
      ) : null}
      {getCheckbox()}
      {meta.touched && meta.error ? (
        <div className="errorLabel">
          <label id={`${props.name}-error`} className={"errFieldMsg"}>
            {meta.error}
          </label>
        </div>
      ) : null}
    </div>
  )
}
