/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import RecoveryCapitalAssessmentModal from "../AdditionalAssesments/RecoveryCapitalAssessmentModal";
import {
    Button,
    Col,
    Modal,
    Row,
    Card,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GetAdditionalAssestmentsStatus, UpdateAdditionalAssessmentStatus } from "../../services/additionalFormsService";
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { setOrigin } from "../../redux/reducers/additionalAssesments";
import { checkJWT } from '../../services/apiCommunicationService';
import { setToken } from "../../redux/reducers/apiAuthentication";


const AdditionalAssessmentModal = (props) => {
    const { show, setShow, additionalAssessmentsConfigs, redirect, name } = props;
    const { id } = useSelector((state) => state.organization.config);
    const { sessionId } = useSelector((state) => state.activityCheckIn);
    const additionalAssessments = useSelector((state) => state.additionalAssessments);
    const activityCheckIn = useSelector((state) => state.activityCheckIn);
    const jwt = useSelector((state) => state.apiAuthentication.token);
    const [addionalAssessmentsStatus, setAdditionalAssessmentsStatus] = useState([]);
    const [activeModal, setActiveModal] = useState();
    const [loadStatus, setLoadStatus] = useState(false);
    const userId = additionalAssessments.origin === "Activity-Check-In" ?
        activityCheckIn?.loginResponse?.userId :
        additionalAssessments.id;
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    useEffect(() => {
        if (id && userId && show) {
            GetAdditionalAssessmentsConfigsStatus();
        }
    }, [userId, show]);

    useEffect(() => {
        if (loadStatus) {
            GetAdditionalAssessmentsConfigsStatus();
        }
    }, [loadStatus]);

    const GetAdditionalAssessmentsConfigsStatus = async () => {
        const forms = additionalAssessmentsConfigs.map(conf => { return conf.additionalForm });
        const localJWT = await checkJWT(jwt, (newJwt) => { dispatch(setToken(newJwt)); })
        const data = await GetAdditionalAssestmentsStatus(id, userId, forms, sessionId, localJWT);
        setAdditionalAssessmentsStatus(data);
    };
    const UpdateStatus = async () => {
        const localJWT = await checkJWT(jwt, (newJwt) => { dispatch(setToken(newJwt)); })
        const res = await UpdateAdditionalAssessmentStatus(addionalAssessmentsStatus, localJWT);
        if (res) {
            addToast(
                "Additional Assessments status successfuly updated",
                {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 10000,
                },
            );
        }
    };
    const checkStatus = (status) => {
        return !status ? "pending" : status?.value.toLowerCase();
    };
    const capitalizeFirstLetter = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };
    const pendingForms = () => {
        return addionalAssessmentsStatus.find(x => x.value === "Pending");
    };

    return (
        <>
            <Modal key={"main-modal"} show={show} onHide={() => null} centered size='lg'>
                <Modal.Header>
                    <Modal.Title>
                        Please complete the following additional assessments
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ position: "relative" }}>
                        <Col>
                            {additionalAssessmentsConfigs?.map(
                                (conf) => {
                                    const actualForm = additionalAssessments.forms?.find(x => x.key === conf.additionalForm);
                                    const status = addionalAssessmentsStatus?.find(x => x.formType === conf.additionalForm);
                                    const checkedStatus = checkStatus(status);
                                    return (<div key={`container-${actualForm?.key}`}>
                                        <Card key={`card-${actualForm?.key}`}>
                                            <Card.Header key={`card-header-${actualForm?.key}`}>{actualForm?.value}
                                                <Button
                                                    key={`btn-status-${actualForm?.key}`}
                                                    disabled
                                                    className={`status ${checkedStatus}`}
                                                >
                                                    Status: {capitalizeFirstLetter(checkedStatus)}
                                                </Button>
                                            </Card.Header>
                                            <Card.Body key={`card-body-${actualForm?.key}`}>
                                                <Card.Text key={`card-text-${actualForm?.key}`}>
                                                    {conf.description}
                                                </Card.Text>
                                                <Button
                                                    key={`btn-complete-${actualForm?.key}`}
                                                    variant="success"
                                                    onClick={() => {
                                                        setActiveModal(conf.additionalForm);
                                                    }}
                                                    disabled={checkedStatus === "completed"}
                                                >
                                                    Complete
                                                </Button>
                                                <Button
                                                    key={`btn-decline-${actualForm?.key}`}
                                                    style={{ marginLeft: '1rem' }}
                                                    variant="danger"
                                                    onClick={() => {
                                                        const newState = addionalAssessmentsStatus.map(obj => {
                                                            if (obj.formType === conf.additionalForm) {
                                                                return { ...obj, value: 'Declined' };
                                                            }
                                                            return obj;
                                                        });
                                                        setAdditionalAssessmentsStatus(newState);
                                                    }}
                                                    disabled={checkedStatus !== "pending" || !addionalAssessmentsStatus || addionalAssessmentsStatus?.length === 0}
                                                >
                                                    Decline
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                        <Button
                                            key={`btn-continue-${actualForm?.key}`}
                                            style={{ marginTop: '1rem', float: 'right' }}
                                            size="md"
                                            variant="secondary"
                                            onClick={() => {
                                                UpdateStatus();
                                                setActiveModal("");
                                                setShow(false);
                                                dispatch(setOrigin(""));
                                                redirect();
                                            }}
                                            disabled={pendingForms() || addionalAssessmentsStatus?.length === 0}
                                        >
                                            Continue
                                        </Button>
                                    </div>);
                                }
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <RecoveryCapitalAssessmentModal
                name={name}
                key={"recovery-capital-assessment-modal"}
                userId={userId}
                show={activeModal === "recovery-capital-assessment"}
                setShow={setActiveModal}
                loadStatus={setLoadStatus}
            />
        </>

    );
}

export default AdditionalAssessmentModal;
