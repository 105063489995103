//Formik
import { useField } from "formik"

//Style
import "./formikInputStyles.css"

//PrimeReact
import { Skeleton } from "primereact/skeleton"
import { RadioButton } from "primereact/radiobutton"

export default function FormikRadioButtonInput({
  name,
  label,
  helpText,
  required,
  options,
  optionLabel,
  optionValue,
  isInitialLoad,
  disabled,
}) {
  const [field, meta, helpers] = useField(name)

  const LabelField = () => {
    if (isInitialLoad) {
      return <Skeleton width="10rem" height="1rem" />
    } else {
      return (
        <label>
          {label} {required ? <span style={{ color: "red" }}> * </span> : null}
        </label>
      )
    }
  }

  const getRadio = () => {
    if (isInitialLoad) {
      return (
        <Skeleton
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          height="42px"
        />
      )
    } else {
      return (
        <div className="radioGroup">
          {options.map((option) => (
            <div className="radioContainer">
              <RadioButton
                checked={field.value === option[optionValue]}
                onChange={(e) => {
                  helpers.setValue(option[optionValue])
                }}
                className={`radioButton ${
                  meta.touched && meta.error ? "p-invalid" : ""
                }`}
                inputId={option[optionValue]}
                disabled={disabled}
              />
              <label
                htmlFor={option[optionValue]}
                className="radioLabel"
                style={{ paddingLeft: "8px" }}
              >
                {option[optionLabel]}
              </label>
            </div>
          ))}

          {meta.touched && meta.error ? (
            <label id={`${name}-error`} className={"errFieldMsg"}>
              {meta.error}
            </label>
          ) : null}
        </div>
      )
    }
  }

  return (
    <div className={"field"}>
      <LabelField />
      <div>
        {helpText ? (
          <div style={{ marginBottom: "10px" }}>
            <small style={{ color: "gray" }}>{helpText}</small>
          </div>
        ) : null}
        {getRadio()}
      </div>
    </div>
  )
}
