import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from 'react-toast-notifications';
import {
  CompleteAdditionalAssessment,
  GetAdditionalAssessmentQuestions
} from "../../services/additionalFormsService";
import moment from 'moment';
import { useEffect } from 'react';
import { checkJWT } from '../../services/apiCommunicationService';
import { setToken } from "../../redux/reducers/apiAuthentication";
import { codes } from '../../helpers/additionalAssessments';
import AssessmentFormQuestionContainer from "../AdditionalAssesments/AssessmentFormQuestionContainer"
import { Dialog } from "primereact/dialog"
import { DateTime } from "luxon"





const RecoveryCapitalAssessmentModal = (props) => {
  const dispatch = useDispatch();
  const { show, setShow, loadStatus } = props;
  const location = useSelector((state) => state.organization.activeLocation)
  const locationName = useSelector((state) => state.organization.activeLocationName)

  const additionalAssessments = useSelector((state) => state.additionalAssessments);
  const activityCheckIn = useSelector((state) => state.activityCheckIn);
  const { groupId } = useSelector((state) => state.organization.config);
  const { addToast } = useToasts();
  const [startTime, setStartTime] = useState(moment().utc());
  const { sessionId } = useSelector((state) => state.activityCheckIn);
  const jwt = useSelector((state) => state.apiAuthentication.token);
  const orgUuid = useSelector((state) => state.organization.config.orgUuid);
  const additionalData = useSelector((state) => state?.additionalAssessments?.additionalData)
  const [questions, setQuestions] = useState()
  const [assessmentId, setAssessmentId] = useState(undefined)
  const [disable, setDisable] = useState(false)
  const [answers, setAnswers] = useState(undefined)

  const getQuestions = async () => {
    try {
      const localJWT = await checkJWT(jwt, (newJwt) => { dispatch(setToken(newJwt)); })
      const response = await GetAdditionalAssessmentQuestions(localJWT, codes.BARC_10);
      setQuestions(response.questions);
      setAssessmentId(response.assessmentId);
      processAnswerSource(response.questions, null);
    }
    catch (error) {
      console.log(error);
      addToast(
        'Can not get Recovery Capital Assessment questions',
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );
    }
  }

  useEffect(() => {
    setStartTime(moment().utc());
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async (values) => {
    try {
      setDisable(true)
      let userId = additionalAssessments.origin === "Activity-Check-In" ?
        activityCheckIn.loginResponse.userId :
        additionalAssessments.id;
      const localJWT = await checkJWT(jwt, (newJwt) => { dispatch(setToken(newJwt)); })


      const singleValueAnswers = values.answers
        .filter((answer) => answer.value)
        .map((answer) => {
          return {
            questionId: answer.questionId,
            answerValue: answer.value.toString(),
          }
        })

      let multiValueAnswers = []
      values.answers
        .filter(
          (answer) =>
            !answer.value &&
            answer.multiValues?.some((multiValue) => multiValue.value)
        )
        .forEach((answer) => {
          multiValueAnswers.push(
            ...answer.multiValues
              .filter((multiValue) => multiValue.value)
              .map((multiValue) => {
                return {
                  questionId: answer.questionId,
                  optionId: multiValue.optionId,
                  answerValue: multiValue.value.toString(),
                }
              })
          )
        })
      let payload = {
        assessmentId: assessmentId,
        remarks: "",
        staffId: "-6",
        staffAlias: "-6",
        participantId: additionalData?.participant_id,
        participantAlias: userId,
        enterpriseId: additionalData?.enterprise_id,
        enterpriseAlias: additionalData?.enterprise_alias,
        organizationId: orgUuid,
        organizationAlias: additionalData?.organization_alias,
        completedDate: DateTime.utc(),
        creatorId: additionalData?.participant_id,
        answers: [...singleValueAnswers, ...multiValueAnswers],
        finalOutcomes: [],
        groupId: groupId,
        data: buildRequestBody([...singleValueAnswers, ...multiValueAnswers], userId)
      }

      CompleteAdditionalAssessment(localJWT, payload)
        .then((result) => {
          if (result) {
            setDisable(false)
            loadStatus(true);
            if (show) setShow(false);
          } else {
            addToast(
              'Unable to save assessment profile',
              {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 10000,
              },
            );
            setDisable(false)
          }
        })
        .catch((error) => {
          addToast(
            'Can not save Recovery Capital Assessment',
            {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 10000,
            },
          );
          setDisable(false)
        })
    }
    catch {
      setDisable(false)
      addToast(
        'Can not save Recovery Capital Assessment',
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );
    }
  };
  const buildRequestBody = (answers, userId) => {
    const data = {
      form_type: "recovery-capital-assessment",
      userId: userId,
      answers: answers,
      form_status: "Completed",
      participantId: userId,
      sessionId: sessionId,
      field_session_engagement: {
        field_engagement_channel: {
          "value": "Other"
        },
        field_engagement_date: {
          "value": moment().utc().format('YYYY-MM-DD'),
        },
        field_engagement_time: {
          "from": startTime.diff(moment().utc().startOf('day'), 'seconds'),
          "to": calcEndTime(),
        }
      },
      field_case_user: {
        "target_id": userId
      },
      field_session_context: {
        "value": "self_assessment"
      },
      organization_location_id: location,
      organization_location_name: locationName,
      start_date_time: startTime,
      end_date_time: moment().utc()
    };
    return data;
  };
  const calcEndTime = () => {
    const startTimeMinutesSinceMidnight = startTime.diff(moment().utc().startOf('day'), 'seconds');
    const actualEndTimeMinutesSinceMidnight = moment().utc().diff(moment().utc().startOf('day'), 'seconds');

    // Limit duration to 8 Minutes minimum
    if ((actualEndTimeMinutesSinceMidnight - startTimeMinutesSinceMidnight) / 60 < 8) {
      const cappedEndTime = startTimeMinutesSinceMidnight + (8 * 60);
      return cappedEndTime;
    }

    return actualEndTimeMinutesSinceMidnight;
  }
  const processAnswerSource = (questions, answers) => {
    let newAnswers = []
    const addAnswer = (question) => {
      let value = null
      let optionValues = []

      if (answers) {
        const questionAnswer = answers.filter(
          (answer) => answer.questionId === question.id
        )

        if (question.type === "checkbox") {
          optionValues = questionAnswer
        } else if (questionAnswer.length > 0) {
          value = questionAnswer[0].answerValue
        }
      }

      newAnswers.push({
        questionId: question.id,
        type: question.type,
        required: question.required,
        conditionalSource: question.conditionalSource,
        conditionalType: question.conditionalType,
        conditionalValue: question.conditionalValue,
        conditionalDisplay: question.conditionalDisplay,
        conditionalRequired: question.conditionalRequired,
        value: value,
        scoreFromAnswer: question.scoreFromAnswer,
        multiValues: question.options?.map((option) => {
          const optionValue = optionValues.filter(
            (optionValue) => optionValue.questionOptionId === option.id
          )

          return {
            optionId: option.id,
            value:
              optionValue.length > 0
                ? optionValue[0].answerValue === "true"
                : null,
            text: option.text,
            scoreValue: option.scoreValue,
          }
        }),
      })
    }

    questions.forEach((question) => {
      if (question.matrix) {
        question.matrix.groups.forEach((group) => {
          group.questions.forEach((groupQuestion) => {
            addAnswer(groupQuestion)
          })
        })
      } else if (question.questionGroup) {
        question.questionGroup.questions.forEach((groupQuestion) => {
          addAnswer(groupQuestion)
        })
      } else if (question.question) {
        addAnswer(question.question)
      }
    })

    setAnswers({ answers: newAnswers })
  }
  return (
    <Dialog
      header="Recovery Capital Assessment"
      visible={show}
      style={{ width: '50vw' }}
      onHide={() => setShow(false)}
      className='dialogContent'
      closable={!disable}
    >
      <AssessmentFormQuestionContainer
        questions={questions}
        onSubmitValues={(values) => { submitForm(values) }}
        answers={answers}
        onCancel={() => {
          if (show) setShow(false);
        }}
        disabled={disable}
      />
    </Dialog>
  )
}

export default RecoveryCapitalAssessmentModal;
