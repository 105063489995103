import { postRequest, getRequest } from "./apiCommunicationService"

/* eslint-disable no-unused-vars */
export async function SubmitAdditionalAssestment(data, groupId, jwt) {
  const res = await postRequest(
    jwt,
    `api/Intake/Kiosk/AdditionalAssestment/${groupId}`,
    data
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to submit Additional Assestment")
  }

  return true
}

export async function GetAdditionalAssestmentsStatus(
  orgId,
  partId,
  forms,
  sessionId,
  jwt
) {
  const res = await postRequest(
    jwt,
    `api/Intake/Kiosk/GetAdditionalAssessmentsStatus/${orgId}/${partId}/${sessionId}`,
    forms
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to get Additional Assestments Status")
  }
  const data = await res.json()
  return data
}

export async function GetAdditionalAssessmentQuestions(jwt, code){
  const res = await getRequest(
    jwt,
    `api/AdditionalAssestmentConfig/systemAssessments/${code}/questions`
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to get Additional Assessments Questions")
  }
  const data = await res.json()
  return data
}

export async function CompleteAdditionalAssessment(jwt, payload){
  console.log("payload", payload)
  const res = await postRequest(
    jwt,
    `api/AdditionalAssestmentConfig/systemAssessments/complete`, payload
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to save Additional Assessments")
  }
  const data = await res.json()
  return data
}

export async function UpdateAdditionalAssessmentStatus(data, jwt) {
  const res = await postRequest(
    jwt,
    `api/Intake/Kiosk/UpdateAdditionalAssessmentsStatus`,
    data
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to update Additional Assestment status")
  }

  return true
}
